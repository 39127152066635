
















































































































































































































































































































































































































































































































































































































































































import Vue from 'vue'
import axios from 'axios'
import router from '../router'

export class SearchOptForm {
  public aConnector: Array<string>;
  public aPolishing: Array<string>;
  public mode: Array<string>;
  public lineCount: Array<string>;
  public outerDiameter: Array<string>;
  public length: Array<string>;
  public others: Array<string>;
  public bConnector: Array<string>;
  public bPolishing: Array<string>;
  constructor () {
    this.aConnector = []
    this.aPolishing = []
    this.mode = []
    this.lineCount = []
    this.outerDiameter = []
    this.length = []
    this.others = []
    this.bConnector = []
    this.bPolishing = []
  }
}

export interface Order {
  id: number | null;
  name: string | null;
  imageFile: string | null;
  overlay: boolean | false;
  orderValue: number | null;
}

export interface Opt {
  id: number | null;
  name: string | null;
  aConnector: string | null;
  bConnector: string | null;
  code: string | null;
  imageFile: string | null;
  overlay: boolean | false;
  value: number | null;
  creDt: string | null;
  updateDt: string | null;
}

export class OrderForm {
  public company: string | null;
  public name: string | null;
  public email: string | null;
  public tel: string | null;
  public fax: string | null;
  public free: string | null;
  public parts: string | null;
  public act: number | null;
  constructor () {
    this.company = null
    this.name = null
    this.email = null
    this.tel = null
    this.fax = null
    this.free = null
    this.parts = ''
    this.act = 3
  }
}

// export interface VForm {
//   validate: () => boolean
//   reset: () => void
//   resetValidation: () => void
// }

export default Vue.extend({
  name: 'OptSearch',
  data: function () {
    return {
      loading: false,
      dialog: false,
      message: '',
      dialog_error: false,
      errorMessage: '',
      searchOptForm: new SearchOptForm(),
      orderForm: new OrderForm(),
      emailRules: [
        (value: string) => !!value || 'メールは必須項目です。',
        (value: string) => /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(value) || '正しいメールアドレスの書式で入力してください。'
      ],
      companyRules: [
        (value: string) => !!value || '会社名は必須項目です。'
      ],
      nameRules: [
        (value: string) => !!value || '担当者名は必須項目です。'
      ],
      isValid: true,
      headers: [
        { text: '', value: 'id', sortable: false },
        { text: 'イメージ', value: 'imageFile' },
        { text: '型名', value: 'name' },
        { text: 'A端コネクタ', value: 'aConnector' },
        { text: 'コード', value: 'code' },
        { text: 'B端コネクタ', value: 'bConnector' },
        { text: '在庫数', value: 'value' },
        { text: '', value: 'action', sortable: false, align: 'end' }
      ],
      opts: new Array<Opt>(),
      order_headers: [
        { text: '型名', value: 'name' },
        { text: 'イメージ', value: 'imageFile' },
        { text: '注文数', value: 'orderValue' },
        { text: '', value: 'action', sortable: false, align: 'end' }
      ],
      orders: new Array<Order>()
    }
  },
  mounted: async function () {
    // レイアウト用テストデータ
    // this.opts.push(
    //   { id: 59178, name: '(A)TLNC 3.15A', imageFile: 'SC-LC-OM4-1.jpg', overlay: false, aConnector: 'LC', bConnector: 'SC', code: 'ショートブーツ', value: 15, creDt: '2021-08-16 03:02:35', updateDt: '2021-08-16 03:04:03' }
    // )
  },
  computed: {
    form (): Vue & { validate: () => boolean } {
      return this.$refs.form as Vue & { validate: () => boolean }
    }
  },
  methods: {
    clearForm () {
      this.searchOptForm = new SearchOptForm()
      // this.phoneNumber = ''
      // this.email = ''
      // this.select = null
      // this.checkbox = null
      // this.$refs.observer.reset()
    },
    async getOpts () {
      try {
        if (this.searchOptForm === null) return
        this.loading = true
        const response = await axios.post('/v1/opt_search.php', this.searchOptForm)
        this.opts = response.data.data
        this.loading = false
      } catch (e) {
        console.log('電子パーツの取得に失敗：', this.opts)
        console.error(e)
      }
    },
    deleteOrder (deleteId: number) {
      if (deleteId === null) return
      this.orders = this.orders.filter((order: Order) => {
        return (order.id !== deleteId)
      })
    },
    addOrder (addId: number) {
      if (addId === null) return
      // すでに登録されていたら登録しない。
      if (this.orders.findIndex(({ id }) => id === addId) !== -1) return

      const part: Opt[] = this.opts.filter((opt: Opt) => opt.id === addId)
      if (part) {
        this.orders.push({ id: part[0].id, name: part[0].name, imageFile: part[0].imageFile, overlay: false, orderValue: 1 })
      }
    },
    async postForm () {
      try {
        if (this.orders.length === 0) return
        if (!this.form.validate()) {
          this.errorMessage = '必須項目が入力されていません。'
          this.dialog_error = true
        } else {
          let freeWord = ''
          this.orders.forEach(function (order: Order) {
            freeWord += ('型名：' + order.name + '  注文数：' + order.orderValue + '\n')
          })
          this.orderForm.parts += freeWord
          const response = await axios.post('/v1/opt_search_send.php', this.orderForm)

          if (response.data.message) {
            this.message = response.data.message
            this.dialog = true
            return
          }
        }
      } catch (e) {
        console.log('フォームの送信に失敗：', this.orderForm)
        console.error(e)
      }
    },
    sendReset () {
      const refForm: any = this.$refs.optForm
      refForm.reset()
      this.dialog = false
    }
  }
})
